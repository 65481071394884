.loading-page {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
}
.loading-page img {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	right: 0;
}
.hidden,
.hide {
	display: none !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.modal-open .ReactModalPortal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	outline: 0;
	overflow-x: hidden;
	overflow-y: auto;
}
.ReactModalPortal .modal-dialog {
	-webkit-transition: -webkit-transform 0.3s ease-out;
	transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
	-webkit-transform: translate(0, -25%);
	transform: translate(0, -25%);
}
.ReactModalPortal .modal-dialog {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
.invoice-logo-w {
	width: 80px;
}
.invoice-logo-w img {
	max-width: 100%;
}
/* @media(min-width: 992px){ */
.modal-lg {
	max-width: 1200px;
}
.modal-sm {
	max-width: 500px;
}
.invoice-w {
	max-width: 1024px;
}
/* } */
.span {
	display: inline-block;
	width: 184px;
}
.user-title {
	font-size: 13px;
}
.table .row-actions a {
	margin-right: 0.43rem;
	color: #3b75e3;
	text-decoration: none;
}
.table .row-actions a:last-child {
	margin-right: 0 !important;
}
.cursor {
	cursor: pointer !important;
}
.el-tablo.bigger .value {
	font-size: 2rem;
}
.el-tablo.bigger .label {
	font-size: 10px;
}
.elabel {
	display: inline;
	padding: 0.2em 0.6em 0.3em;
	font-size: 75%;
	font-weight: bold;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25em;
}
.elabel-danger {
	background-color: #d9534f;
}
.elabel-success {
	background-color: #5cb85c;
}
.elabel-info {
	background-color: #383d41;
}
.elabel-warning {
	background-color: #ffb443;
}
.scroll {
	overflow-x: scroll !important;
}
.report {
	width: 100% !important;
	max-width: 100% !important;
}
.scroll::-webkit-scrollbar {
	-webkit-appearance: none;
}

.label-danger .label,
.label-danger .value {
	color: #d9534f !important;
}
.scroll::-webkit-scrollbar:vertical {
	width: 11px;
}

.scroll::-webkit-scrollbar:horizontal {
	height: 11px;
}

.scroll::-webkit-scrollbar-thumb {
	border-radius: 8px;
	border: 2px solid white; /* should match background, can't be transparent */
	background-color: rgba(0, 0, 0, 0.5);
}

.scroll::-webkit-scrollbar-track {
	background-color: #fff;
	border-radius: 8px;
}

.input-group-preappend,
.input-group-append {
	display: flex;
}
.input-group-append {
	margin-left: -2px;
}
.input-group > .input-group-append > .btn,
.all-wrapper .input-group > .input-group-append > .fc-button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.all-wrapper .input-group > .input-group-prepend:not(:first-child) > .fc-button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.all-wrapper
	.input-group
	> .input-group-prepend:first-child
	> .fc-button:not(:first-child),
.input-group
	> .input-group-prepend:first-child
	> .input-group-text:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: center;
	white-space: nowrap;
	border: 1px solid #cecece;
	border-radius: 4px;
	background: #e9ecef;
}

.react-datepicker-popper {
	z-index: 1000 !important;
}
.white-bg {
	background: #fff !important;
}
.top-menu-secondary li:focus {
	outline: none !important;
}
.link {
	color: #047bf8 !important;
	text-decoration: underline !important;
}

.element-box .element-header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	padding: 1rem 0;
	margin-top: 2rem;
	position: relative;
	z-index: 1;
}

.element-box .element-header:after {
	content: '';
	background-color: #047bf8;
	width: 22px;
	height: 6px;
	border-radius: 2px;
	display: block;
	position: absolute;
	bottom: -3px;
	left: 0px;
}
.top-settings {
	cursor: pointer;
}

.support-index .support-tickets-header .tickets-control .new-ticket {
	position: relative;
	margin-left: auto;
}
.ticket-header span {
	font-weight: 100;
}
.btn {
	cursor: pointer;
}

.modal-md {
	max-width: 768px;
}
.modal-ol {
	max-width: 1024px;
}
.controls-below-table {
	margin: 1rem;
}
.disabled-link {
	pointer-events: none;
	color: #495057;
}
.ticket-closed {
	font-style: italic;
	text-align: center;
	font-weight: 500;
	font-size: 0.85rem;
	border: 1px #f3f3f7 solid;
	color: #495057;
}

.row-expand-icon {
	color: #1890ff;
	text-decoration: none;
	cursor: pointer;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	display: inline-block;
	width: 17px;
	height: 17px;
	color: inherit;
	line-height: 13px;
	text-align: center;
	background: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 2px;
	outline: none;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.row-collapsed::after {
	content: '+';
}
.row-expanded::after {
	content: '-';
}
.expanded-row {
	background: #fbfbfb !important;
}
.expanded-row .table {
	background: #fbfbfb;
}
.expanded-row .table-striped tr {
	background: #fbfbfb !important;
}
.footer-w .logo {
	width: 40px;
}
.footer-w .logo img {
	max-width: 100%;
}
.has-sub-menu {
	position: relative;
}
.top-menu-secondary ul > li.selected .sub-menu-w {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateX(0px) scale(1);
	transform: translateX(0px) scale(1);
}
.top-menu-secondary .sub-menu-w {
	z-index: 9999;
	visibility: hidden;
	opacity: 0;
	-webkit-transform: translateX(40px) scale(0.95);
	transform: translateX(40px) scale(0.95);
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	position: absolute;
	background-color: #1b55e2;
	color: #fff;
	left: 0;
	overflow: hidden;
	text-align: left;
	box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
	/* padding-bottom: 20px; */
}
.top-menu-secondary .sub-menu-w .sub-menu-i {
	display: -webkit-box;
	display: flex;
}
.top-menu-secondary ul > li.has-sub-menu.selected .sub-menu {
	display: block;
}
.top-menu-secondary .sub-menu-w > ul,
.top-menu-secondary .sub-menu-w .sub-menu-i > ul {
	list-style: none;
	padding: 0px;
	/* min-width: 250px; */
}
.top-menu-secondary ul.sub-menu {
	padding: 0px;
	padding-left: 55px;
	display: none;
	list-style: none;
}
.top-menu-secondary .sub-menu-w > ul > li,
.top-menu-secondary .sub-menu-w .sub-menu-i > ul > li {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.top-menu-secondary ul.sub-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width:100%;
}
.top-menu-secondary ul.sub-menu li a {
	padding: 0.4rem 10px 0.4rem 10px;
	display: block;
	position: relative;
	font-size: 0.81rem;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.top-menu-secondary .sub-menu-w > ul > li > a,
.top-menu-secondary .sub-menu-w .sub-menu-i > ul > li > a {
	color: #fff;
	display: block;
	padding: 12px 50px;
	font-size: 0.99rem;
	position: relative;
	white-space: nowrap;
}
.has-bg {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	height: 60vh;
	opacity: 0.8;
}

.has-bg .intro-text {
	margin-top: calc(30vh - 50px);
}

.status-pill.purple {
    background-color: #bc1ac2;
}

.loan__status {
    position: absolute;
    bottom: 4px;
    left: 16%;
}

.loan__status .loan__text {
    font-size: 10px;
    margin-left: 5px;
}

@media (max-width: 575px) {
    .loan__status {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
}
.support-icon {
	background-color: #e65252;
    color: #fff;
    border-radius: 6px;
    font-weight: 500;
    position: absolute;
    top: 23px;
    left: 0px;
    padding: 4px 4px;
    vertical-align: middle;
    font-size: 9px;
    line-height: 1;
}

.hint-icon {	
    position: absolute;
    top: 23px;
    right: 0px;
}

.support-mobile-icon {
	display: inline !important;
    position: absolute;
    top: 30%;
    right: 65%;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background: tomato;
    text-align: center;
    padding: 0px !important;
	font-size: 11px;
}

.introjs-hint-pulse {
	position: relative;
	border: 5px solid rgba(255, 90, 90, 0.9) !important;
	background:rgba(255, 90, 90, 0.9) !important;
	display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.introjs-hint-dot {
    border: 10px solid rgba(255, 90, 90, 0.9) !important;
}
