.auth-box-w {
    max-width: 380px;
    width: 380px;
    margin: 0;
    background-color: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    padding-top: 30px;
}

.auth-box-w.wider {
    max-width: 500px
}

.show-mobile {
  display: block !important;
}

.auth-box-w .logged-user-w {
    padding-top: 0px;
    padding-bottom: 10px
}

.auth-box-w .logo-w {
    text-align: center;
    padding: 20%
}

.auth-box-w .auth-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
    padding-left: 80px
}

.auth-box-w .auth-header:after {
    content: "";
    background-color: #047bf8;
    width: 32px;
    height: 7px;
    border-radius: 2px;
    display: block;
    position: absolute;
    bottom: -4px;
    left: 80px
}

.auth-box-w form {
    padding: 20px 40px 30px 60px;
    padding-bottom: 30px;
}

.auth-box-w form .form-check-inline {
    margin-left: 1rem
}

.auth-box-w form .form-group {
    position: relative
}

.auth-box-w form .form-group .pre-icon {
    position: absolute;
    top: 40px;
    font-size: 24px;
    color: #047bf8;
    left: -38px
}

.auth-box-w .buttons-w {
    padding-top: 1rem;
    margin-top: 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05)
}

.auth-box-w.centered {
    text-align: center
}

.auth-box-w.centered .auth-header {
    padding-left: 0px
}

.auth-box-w.centered .auth-header:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442;
}
.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}
.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}
.has-error .form-control-feedback {
  color: #a94442;
}
.has-feedback label ~ .form-control-feedback {
  top: 25px;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
    font-size: 85%;
}

.alert {
    padding: 15px !important;
    margin: 0 5px;
    border: 1px solid transparent;
    border-radius: 0 !important;
}
.alert h4 {
    margin-top: 0;
    color: inherit;
  }
  .alert .alert-link {
    font-weight: bold;
  }
  .alert > p,
  .alert > ul {
    margin-bottom: 0;
  }
  .alert > p + p {
    margin-top: 5px;
  }
  .alert-dismissable,
  .alert-dismissible {
    padding-right: 35px;
  }
  .alert-dismissable .close,
  .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: 0;
    color: inherit;
  }
  .alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
  .alert-success hr {
    border-top-color: #c9e2b3;
  }
  .alert-success .alert-link {
    color: #2b542c;
  }
  .alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }
  .alert-info hr {
    border-top-color: #a6e1ec;
  }
  .alert-info .alert-link {
    color: #245269;
  }
  .alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }
  .alert-warning hr {
    border-top-color: #f7e1b5;
  }
  .alert-warning .alert-link {
    color: #66512c;
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .alert-danger hr {
    border-top-color: #e4b9c0;
  }
  .alert-danger .alert-link {
    color: #843534;
  }


h4,
.h4 {
  font-size: 18px;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.fade10 {
  -webkit-animation:2s ease 0.4s showIntroDescription;animation:2s ease 0.4s showIntroDescription;-webkit-animation-fill-mode:both;animation-fill-mode:both
}
